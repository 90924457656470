.landing {
    box-shadow: 0.1rem 0.1rem 2rem rgb(25, 25, 25);
}

.overlayContent {
    text-align: center;
    left: 20vw;
    padding-top: 11vw;
    background-color: rgba(82, 82, 82, 0.233);
    height: 100%; /* Adjusted to fully cover the landing height */
}

.overlayImg1 {
    object-fit: cover;
    height: 30vw;
}

.overlayTxt {
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 700;
    font-size: 5vw;
    text-shadow: black 0.15rem 0.15rem 1.2rem;
}
