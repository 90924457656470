.services {
    background-color: rgb(201, 238, 21);
    color: black;
    margin: 0 2rem;
    border-radius: 3rem;

    padding: 1rem 1rem 0 1rem;
}

.helpText {
    display: none;
}

.servicesHead {
    padding-top: 1.5rem;
    text-align: center;
    display: grid;
    justify-content: center;
}

.SHeadTxt {
    padding: 0.5rem 2.5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    width: fit-content;
    color: rgb(201, 238, 21);
    background-color: rgb(84, 84, 84);
    font-size: 0.9rem;
    font-family: "BIZ UDPGothic", sans-serif;
    text-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.568);
}

.SValuImg {
    width: 100%;
    max-width: 21.1rem;
}

.servicesHeadline, .servicesHeadline2 {
    font-family: "BIZ UDPGothic", sans-serif;
}

.servicesHeadline2 {
    margin-top: 0.75rem;
}

.serviceLower {
    padding: 2rem 0 3rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.service {
    font-size: 1.1rem;
    padding: 2rem 0;
    font-family: "BIZ UDPGothic", sans-serif;
}

.serviceInfo {
    opacity: 0;
    position: absolute;
    min-height: 4rem;
    max-width: 24rem;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    margin: 1rem 0;
    background-color: rgb(244, 244, 244);
    box-shadow: 0.1rem 0.1rem 2rem rgba(134, 134, 134, 0.416);
    transition: opacity 0.3s ease-in-out; /* Smooth fade-in effect */
}

.serviceInfo:hover {
    opacity: 100%;
    transition: opacity 0.2s ease-in-out; /* Smooth hover exit */
}

.serviceInfo:hover {
    opacity: 100%;
}

.serviceLine {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.ServiceIcon {
    width: 3rem;
    margin-right: 1rem;
}

@media (max-width: 768px) {
    .services {
        margin: 0 1rem;
        padding: 1rem;
    }

    .helpText {
        display: block;
        font-family: "BIZ UDPGothic", sans-serif;
        text-align: center;
        margin-top: 0.5rem;
    }

    .serviceLower {
        flex-direction: column;

        padding: 1rem 0;
        padding-left: 3rem;
    }

    .serviceInfo {
        max-width: 70vw;

    }


    .service {
        font-size: 1.5rem;
        padding: 2rem 0;
    }

    .ServiceIcon {
        width: 2.5rem;
        margin-right: 0.5rem;
    }
}

@media (max-width: 480px) {
    .services {
        margin: 0 1rem;
        padding: 0.5rem;
    }

    .SHeadTxt {
        font-size: 0.6rem;
    }

    .SValuImg {
        width: 100%;
        max-width: 15.7rem;
    }

    .service {
        font-size: 0.9rem;
        padding: 1.5rem 0;
    }

    .ServiceIcon {
        width: 1.5rem;
        margin-right: 0.5rem;
    }
    .serviceInfo {
        max-width: 70vw;
        font-size: 0.75rem;
    }
}
