.about {
    width: 100vw; /* Scaled down */
    background-image: url(../Assets/images/TelineKuvaRoiske.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 45vw; /* Scaled down */
}

.helpText {
    display: none;
}

.firstSection {
    padding-top: 1.6rem; /* Scaled down */
    text-align: center;
    padding-bottom: 1.2rem; /* Scaled down */
}

.aboutHead {
    display: grid;
    justify-content: center;
}

.aboutHeadline {
    width: fit-content;
    color: black;
    font-size: 1.6rem; /* Scaled down */
    font-family: "BIZ UDPGothic", sans-serif;
    text-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.26); /* Scaled down */
    text-decoration: underline rgb(201, 238, 21);
    text-underline-offset: 0.4rem; /* Scaled down */
}

.valuImg {
    width: 16rem; /* Rounded down */
}

.valuImg2 {
    width: 17.3rem; /* Rounded down */
}

.aboutTxt {
    margin: 0.8rem 4.8rem 0 4.8rem; /* Scaled down */
    font-size: 1.2rem; /* Scaled down */
    font-family: "BIZ UDPGothic", sans-serif;
    color: rgb(0, 0, 0);
}

.secondSection {
    display: flex;
    padding-top: 3.2rem; /* Scaled down */
}

.infoSection {
    display: flex;
    justify-content: space-between;
    width: 72vw; /* Scaled down */
    margin-top: 2.6rem; /* Scaled down */
    padding: 1.5vw 8vw 3.2rem 8vw; /* Scaled down */
}

.chooseHeadline {
    font-size: 1.9rem; /* Scaled down */
    font-family: "BIZ UDPGothic", sans-serif;
    text-align: center;
}

.infoWindow {
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 1.6rem; /* Scaled down */
    align-items: center;
}

.infoImg {
    width: 8rem; /* Scaled down */
    box-shadow: 0.08rem 0.08rem 0.8rem black; /* Scaled down */
    border-radius: 12rem; /* Scaled down */
    object-fit: contain;
}

.infoImgTxt {
    position: absolute;
    color: black;
    font-size: 1.5rem; /* Scaled down */
    text-shadow: 0.08rem 0.08rem 0.24rem white; /* Scaled down */
}

/* First Info Text: Smaller size */
.infoTxt1 {
    opacity: 0;
    height: 12rem;
    width: 12rem;
    border-radius: 12rem;
    display: flex; /* Enable Flexbox */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    text-align: center; /* Ensure multiline text remains centered */
    color: black;
    font-size: 1rem; /* Slightly smaller text */
    background-color: rgba(202, 238, 21, 0.9);
    position: absolute;
    transition: opacity 0.5s ease; /* Smooth fade-in effect */
}

/* Second Info Text: Larger size */
.infoTxt2 {
    opacity: 0;
    height: 13.5rem; /* Largest height */
    width: 13.5rem; /* Largest width */
    border-radius: 14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    font-size: 1rem; /* Slightly larger text size */
    background-color: rgba(202, 238, 21, 0.9);
    position: absolute;
    transition: opacity 0.5s ease;
}

/* Third Info Text: Contains special text styling */
.infoTxt3 {
    opacity: 0;
    height: 13.5rem; /* Largest height */
    width: 13.5rem; /* Largest width */
    border-radius: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    font-size: 1rem; /* Slightly larger text size */
    background-color: rgba(202, 238, 21, 0.9);
    position: absolute;
    transition: opacity 0.5s ease;
}

.infoTxt1:hover, .infoTxt2:hover, .infoTxt3:hover {
    opacity: 95%;
}

.houseImgSection {
    max-width: 20vw; /* Scaled down */
    padding-left: 8vw; /* Scaled down */
    padding-bottom: 3.2rem; /* Scaled down */
}

.houseImg {
    max-height: 18rem; /* Scaled down */
}
@media (max-width: 950px) {
    .about {
        background-position: right; /* Center the background */
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
/* Media Query for screens smaller than 768px */
@media (max-width: 768px) {
    .about {
        background-position: center; /* Center the background */
        padding-left: 2rem;
        padding-right: 2rem;
        align-items: center;
    }

    .secondSection {
        display: grid;
        margin-bottom: 1rem;
    }

    .aboutHeadline {
        font-size: 1.4rem; /* Slightly smaller headline */
        margin-bottom: 1rem;
    }

    .helpText {
        display: block;
        font-size: 2.5vw;
        text-align: center;
        font-family: "BIZ UDPGothic", sans-serif;
    }

    .aboutTxt {
        margin: 0.8rem 2rem; /* Reduce horizontal margin */
        font-size: 1rem; /* Smaller font */
    }

    .valuImg, .valuImg2 {
        width: 12rem; /* Smaller images */
    }

    .infoSection {
        flex-direction: row; /* Stack info blocks vertically */
        width: 100%; /* Use full width */
        margin-top: 1.6rem;
        margin-right: 20vw;
        padding: 1rem; /* Reduce padding */
    }

    .infoWindow {
        flex-direction: row; /* Stack image and text */
        margin-bottom: 1rem; /* Add spacing between blocks */
    }

    .infoImg {
        width: 6rem; /* Smaller circular images */
    }

    .infoTxt1, .infoTxt2, .infoTxt3 {
        font-size: 0.78rem; /* Smaller info text */
        height: 10rem; /* Adjust size */
        width: 10rem;
        border-radius: 10rem; /* Make proportional to the size */
    }

    .chooseHeadline {
        font-size: 1.6rem; /* Reduce headline font size */
    }

    .infoImgTxt{
        font-size: 1.18rem;
    }
}

/* Media Query for screens smaller than 650px */
@media (max-width: 650px) {
    .about {
        background-position: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .secondSection {
        padding-top: 0.8rem;
    }

    .valuImg, .valuImg2 {
        width: 10rem; /* Smaller images compared to 768px */
    }

    .aboutHeadline {
        font-size: 1.3rem; /* Slightly smaller than 768px */
    }

    .aboutTxt {
        font-size: 0.9rem; /* Reduce text size further */
        margin: 0.5rem 1.5rem; /* Less margin */
    }

    .infoSection {
        display: grid; /* Switch to CSS Grid */
        grid-template-columns: 1fr 1fr; /* 2 columns */
        grid-template-rows: auto auto; /* Adjust rows dynamically */
        grid-gap: 1.5rem; /* Add spacing between cards */
        justify-items: center; /* Center content horizontally */
        padding: 1rem; /* Adjust padding */
    }

    /* Style the Info Windows */
    .infoWindow:nth-child(1) {
        grid-column: 1; /* First column */
        grid-row: 1; /* First row */
    }

    .infoWindow:nth-child(2) {
        grid-column: 2; /* Second column */
        grid-row: 1; /* First row */
    }

    .infoWindow:nth-child(3) {
        grid-column: 1 / -1; /* Span both columns */
        grid-row: 2; /* Second row */
        justify-self: center; /* Center the third item horizontally */
    }

    .infoWindow {
        margin-bottom: 1.2rem; /* Spacing between items */
    }

    .infoImg {
        width: 5.5rem; /* Reduce size slightly */
    }

    .infoTxt1, .infoTxt2, .infoTxt3 {
        font-size: 0.66rem; /* Smaller text size */
        height: 9rem;
        width: 9rem;
        border-radius: 9rem; /* Proportional radius */
    }

    .houseImgSection {
        max-width: 50%; /* Resize house image */
        padding-bottom: 1.8rem;
    }

    .houseImg {
        max-height: 13rem; /* Adjust height for intermediate size */
    }

    .chooseHeadline {
        font-size: 1.5rem; /* Smaller font size */
    }
}

/* Media Query for screens smaller than 480px */
@media (max-width: 480px) {
    .about {
         /* Remove background image for simplicity */
        text-align: center; /* Center all text */
        padding: 1rem; /* Add uniform padding */
    }

    .firstSection, .secondSection {
        padding: 1rem 0; /* Adjust section paddings */
    }

    .aboutHeadline {
        font-size: 1.2rem; /* Make headline even smaller */
    }

    .aboutTxt {
        font-size: 0.8rem; /* Reduce font size */
        margin: 0.5rem; /* Minimal margin on smaller screens */
    }

    .valuImg, .valuImg2 {
        width: 8rem; /* Further reduce image size */
    }

    .infoSection {
        display: grid; /* Use CSS Grid */
        grid-template-columns: 1fr 1fr; /* 2 columns */
        grid-template-rows: auto auto; /* Rows adjust based on content */
        grid-gap: 1rem; /* Add spacing between cards */
        justify-items: center; /* Center items horizontally */
        padding: 0; /* Remove extra padding */
    }

    /* Style the Info Windows to fit into the grid */
    .infoWindow:nth-child(1) {
        grid-column: 1; /* First column */
        grid-row: 1; /* First row */
    }

    .infoWindow:nth-child(2) {
        grid-column: 2; /* Second column */
        grid-row: 1; /* First row */
    }

    .infoWindow:nth-child(3) {
        grid-column: 1 / -1; /* Span entire width */
        grid-row: 2; /* Second row */
        justify-self: center; /* Center content */
    }

    .infoWindow {
        flex-direction: column; /* Stack text and image */
        margin-bottom: 1.5rem; /* Add space between items */
    }

    .infoImg {
        width: 5rem; /* Smaller circular images */
    }

    .infoTxt1, .infoTxt2, .infoTxt3 {
        font-size: 0.75rem; /* Make font size even smaller */
        height: 9.5rem;
        width: 9.5rem;
        border-radius: 8rem; /* Proportional radius */
    }

    .infoImgTxt {
        font-size: 0.95rem;
    }

    .houseImgSection {
        max-width: 60%; /* Image should not be too small */
        padding-bottom: 1.5rem; /* Adjust padding */
    }

    .houseImg {
        max-height: 12rem; /* Resize the image */
    }

    .chooseHeadline {
        font-size: 1.4rem; /* Reduce headline size */
    }
}
