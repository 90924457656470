.contacts {
    text-align: center;
    margin-top: 3.2rem; /* Scaled down */
    padding: 1.6rem; /* Scaled down */
    background-image: url(../Assets/images/JuhoJaTeppoRoiske.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.CHead {
    display: flex;
    justify-content: center;
}

.contactsHeadline {
    padding: 0 2.4rem; /* Scaled down */
    border-top-left-radius: 0.8rem; /* Scaled down */
    border-top-right-radius: 0.8rem; /* Scaled down */
    width: fit-content;
    color: black;
    background-color: rgb(201, 238, 21);
    font-size: 1.6rem; /* Scaled down */
    font-family: "BIZ UDPGothic", sans-serif;
    text-shadow: 0.08rem 0.08rem 0.32rem rgba(0, 0, 0, 0.11); /* Scaled down */
}

.valuImg3 {
    width: 100%;
    max-width: 16.5rem; /* Scaled down */
}

.contactsSctns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 2rem 0 2rem;
}

.contactCard {
    width: 24vw; /* Scaled down */
    min-height: 34vw;
    max-width: unset; /* Keeps the card responsive */
    background-color: rgb(202, 238, 21);
    padding: 1.2rem; /* Scaled down */
    border-radius: 0.8rem; /* Scaled down */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); /* Scaled down */
    transition: transform 0.3s, box-shadow 0.3s;
}

.contactCard:hover {
    transform: scale(1.07);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2); /* Scaled down */
}

.contactImg {
    width: 100%;
    max-width: 2048px; /* Scaled down */
    height: auto;
    aspect-ratio: 1; /* Keeps the square ratio */
    object-fit: cover; /* Ensures the image fills the area */
}

.middleLine {
    background-color: rgb(201, 238, 21);
    width: 0.24rem; /* Scaled down */
    height: 12rem; /* Scaled down */
    border-radius: 8rem; /* Scaled down */
}

.NameSctn {
    margin-top: 0.8rem; /* Scaled down */
    font-size: 1rem; /* Scaled down */
    font-family: "BIZ UDPGothic", sans-serif;
    color: #333;
    min-height: 4.8rem; /* Scaled down */
}

.name {
    font-size: 1.2rem; /* Scaled down */
    font-weight: bold;
}

.title {
    font-size: 1rem; /* Scaled down */
    color: #363636;
}

.phone {
    font-size: 0.8rem; /* Scaled down */
    color: #4d4d4d;
}

@media only screen and (max-width: 768px) {
    .contactsHeadline {
        font-size: 1.5rem; /* Scaled down */
        padding: 0.4rem 1.6rem; /* Scaled down */
    }

    .valuImg3 {
        max-width: 14.2rem; /* Scaled down */
        margin-bottom: 0.8rem; /* Scaled down */
    }

    .contactsSctns {
        display: flex;
        justify-content: space-between;
        margin: 0 1rem 0 1rem;
    }

    .middleLine {
        display: none;
    }

    .contactCard {
        max-width: none;
        width: 28vw;
        max-height: 15rem;
    }

    .NameSctn {
        font-size: 0.8rem; /* Scaled down */
    }

    .name {
        font-size: 1rem; /* Scaled down */
    }

    .title {
        font-size: 0.8rem; /* Scaled down */
    }

    .phone {
        font-size: 0.7rem; /* Scaled down */
    }

    .contactImg {
         /* Scaled down */
    }
}

@media (max-width: 480px) {
    .contactsSctns {
        margin: 0;
    }
    .contactCard {
        width: 29vw;
        max-height: 15rem;
        padding: 0.8rem 0.1rem 0 0.1rem;
    }

    .name {
        font-size: 0.8rem; /* Scaled down */
    }

    .title {
        font-size: 0.75rem; /* Scaled down */
    }

    .phone {
        font-size: 0.7rem; /* Scaled down */
    }
    .contactImg {
        height: 40vw;
        max-height: 25vw !important;
    }
    .NameSctn {
        margin-top: 0.2rem;
    }
}

@media (max-width: 360px) {
    .contactCard {
        width: 34vw;
    }
}
