.navbar {
    height: 5vw;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgb(201, 238, 21);
    color: rgb(0, 0, 0);
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.247) 0rem 0.5rem 2em;
}

.navVisible {
    transform: translateY(0);
}

.navHidden {
    transform: translateY(-100%);
}

.logoTxt {
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 700;
    font-size: 3vw;
}

.menuIcon {
    object-fit: cover;
    height: 2.7vw;
    cursor: pointer;
}

.menuIcon2 {
    object-fit: cover;
    height: 2.7vw;
    cursor: pointer;
}

.menuimgsection {
    margin-right: 2rem;
}

.pageLinks {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    top: 0;
    left: 0;
    background-color: rgb(84, 84, 84);
    color: rgb(255, 255, 255);
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.247) 0rem 0.5rem 2em;
    padding: 1rem;
}

.linkSection {
    display: flex;
    flex-direction: column;
    
    align-items: center;
}
  
.pageLinksVisible {
    transform: translateY(0);
}
  
.pageLinksHidden {
    transform: translateY(-100%);
}

.pageLink {
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 1.5rem;
    padding: 1rem;
    color: white;
}

.pageLink:hover {
    color: rgb(203, 203, 203);
    text-decoration-line: underline;
}

@media (min-width: 1025px) {
    .pageLinks {
        flex-direction: row;
        justify-content: space-between;
        height: 5vw;
    }
    .pageLink {
        margin-bottom: 0;
        margin-right: 1.5rem;
        
    }
    .linkSection {
        flex-direction: row;
        
    }
    
}








