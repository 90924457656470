.footer {
    font-family: "BIZ UDPGothic", sans-serif;
    color: black;
    overflow: hidden;
}

p {
    font-family: "BIZ UDPGothic", sans-serif;
}

.someRowName {
    font-size: 1.4rem; /* Scaled down */
    font-family: "BIZ UDPGothic", sans-serif;
    text-shadow: 0.24rem 0.24rem 0.16rem rgba(84, 84, 84, 0.39); /* Scaled down */
}

.someRow {
    background-color: rgb(191, 224, 22);
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 1.8rem; /* Scaled down */
    font-family: "BIZ UDPGothic", sans-serif;
    align-items: center;
    box-shadow: -0.4rem -0.4rem 0.8rem rgba(0, 0, 0, 0.11); /* Scaled down */
}

.someBtns {
    align-items: center;
    display: flex;
}

.SclsImg {
    height: 1.6rem; /* Scaled down */
    margin: 0.08rem 0 0.08rem 1.2rem; /* Scaled down */
}

.pystyRivit {
    padding: 1.2rem 0; /* Scaled down */
    background-color: rgb(201, 238, 21);
    display: flex;
    justify-content: space-around;
    font-size: 1.12rem; /* Scaled down */
    font-family: "BIZ UDPGothic", sans-serif;
}

.footerLogo {
    object-fit: contain;
    height: 3.6rem; /* Scaled down */
}

.footerName {
    font-weight: 700;
    font-size: 1.12rem; /* Scaled down */
}

.footerAdrs {
    color: rgb(73, 73, 73);
    font-size: 0.8rem; /* Scaled down */
}

.rowHead {
    margin-bottom: 0.6rem; /* Scaled down */
    font-family: "BIZ UDPGothic", sans-serif;
    font-size: 1rem; /* Scaled down */
    text-decoration: underline;
    text-underline-offset: 0.2rem; /* Scaled down */
}

.footerNav {
    display: grid;
    font-size: 0.9rem; /* Scaled down */
}

.Link {
    color: black;
}

.Link:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 0.2rem; /* Scaled down */
}

.emailLink {
    color: black;
    font-family: "BIZ UDPGothic", sans-serif;
    font-size: 0.9rem;
}

.rights {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 2.4rem; /* Scaled down */
    background-color: rgba(202, 238, 21, 0.692);
    font-family: "BIZ UDPGothic", sans-serif;
    font-size: 0.9rem; /* Scaled down */
}

.rightsTxt {
    color: black;
    font-size: 0.9rem; /* Scaled down */
    padding: 0.5rem 1rem;
    font-family: "BIZ UDPGothic", sans-serif;
}

@media only screen and (max-width: 825px) {
    .someRow {
        flex-direction: column;
        align-items: center;
    }

    .someBtns {
        margin-top: 0.8rem; /* Scaled down */
    }

    .pystyRivit {
        flex-direction: column;
        align-items: center;
        font-size: 0.9rem; /* Scaled down */
    }

    .rights {
        flex-direction: column;
        align-items: center;
        font-size: 0.8rem; /* Scaled down */
    }

    .rightsTxt {
        margin-bottom: 0.8rem; /* Scaled down */
    }

    .frow {
        margin-bottom: 1rem; /* Scaled down */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footerNav {
        margin-bottom: 1rem; /* Scaled down */
        display: flex;
    }
    .Link {
        margin: 0.5rem 2rem;
    }
    .srow {
        margin-left: 0;
        text-align: center;
    }
    .row3 {
        margin-left: 0;
        text-align: center;
    }
}

@media (max-width: 670px) {
    .footerNav {
        margin-bottom: 1rem; /* Scaled down */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Link {
        margin: 0.2rem 0;
    }
}
