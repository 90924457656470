.NotFound {
    text-align: center;
    margin-top: 20rem;
    color: black;
}

.NFHeadline {
    font-size: 3rem;
}

.NFPara {
    font-size: 1.75rem;
    margin-bottom: 2rem;
}

.BackToHomePage {
    color: black;
    padding: 0.5rem;
    background-color: rgb(217, 255, 25);
    border: solid black 0.1rem;
    border-radius: 0.5rem;
}
