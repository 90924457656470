.navbar {
    height: 5.12vw; /* Scaled down */
    width: 100vw;
    margin: 0 auto;
    padding: 0.8rem 1.6rem; /* Scaled down */
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgb(201, 238, 21);
    color: rgb(0, 0, 0);
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.247) 0 0.4rem 1.6em; /* Scaled down */
}

.navVisible {
    transform: translateY(0);
}

.navHidden {
    transform: translateY(-100%);
}

.logoTxt {
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 700;
    font-size: 2.4vw; /* Scaled down */
    color: black;
    text-shadow: 0.08rem 0.08rem 0.32rem rgba(0, 0, 0, 0.26); /* Scaled down */
}

.logoTxt:hover {
    cursor: pointer;
}

.menuIcon {
    object-fit: cover;
    height: 2.16vw; /* Scaled down */
    cursor: pointer;
    position: absolute;
    right: 1.6rem; /* Scaled down */
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.menuIcon2 {
    object-fit: cover;
    height: 2.16vw; /* Scaled down */
    cursor: pointer;
    position: absolute;
    right: 1.6rem; /* Scaled down */
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}

.pageLinks {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background-color: rgb(84, 84, 84);
    color: rgb(255, 255, 255);
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.247) 0 0.4rem 1.6em; /* Scaled down */
    padding: 0.8rem; /* Scaled down */
    text-align: center;
}

.linkSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
}

.pageLinksVisible {
    transform: translateY(0);
    height: auto;
    z-index: 3;
}

.pageLinksHidden {
    transform: translateY(-100%);
}

.pageLink {
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 400;
    font-size: 1.2rem; /* Scaled down */
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 0.8rem 0 0; /* Scaled down */
    padding: 0.8rem; /* Scaled down */
    text-align: center;
}

.pageLink:hover {
    color: rgb(203, 203, 203);
    text-decoration-line: underline;
}
@media (max-width: 1025px) {
    .pageLink {
        font-size: 0.9rem; /* Scale up links for touch readability */
        margin-right: 1rem; /* Add spacing between links */
    }
}
/* Adjust navbar for smaller devices */
@media (max-width: 768px) {
    .navbar {
        height: auto;
    }

    .linkSection {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .navbar {
        height: 5vw;
    }

    .logoTxt {
        font-size: 1rem;
    }

    .linkSection {
        flex-direction: column;
    }
}

@media (max-width: 350px) {
    .navbar {
        height: 0.8rem;
    }

    .logoTxt {
        font-size: 0.8rem;
    }

    .linkSection {
        flex-direction: column;
    }
}
